import { fetch } from 'domain-task';

export const LOGIN_BEGIN = 'LOGIN_BEGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const loginBegin = () => ({
    type: LOGIN_BEGIN,
});

export const loginSuccess = loginResponse => ({
    type: LOGIN_SUCCESS,
    payload: loginResponse,
});

export const loginFailure = error => ({
    type: LOGIN_FAILURE,
    payload: error,
});

export function login(loginRequestData) {
    return (dispatch) => {
        dispatch(loginBegin());
        return fetch(
            '/api/v1/security/login', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(loginRequestData),
        },
        ).then(
            res => res.json(),
        ).then((data) => {
            if (data.isFailed) {
                dispatch(loginFailure(data.errorMessage));
            } else {
                dispatch(loginSuccess(data.result));
            }
        }).catch((error) => {
            dispatch(loginFailure(error));
        });
    }; // Pitea Andreea
}
