import {
    ENVIRONMENT_BEGIN ,
    ENVIRONMENT_SUCCESS ,
    ENVIRONMENT_FAILURE 
  } from './environmentIndicatorAction';
  
  
  const defaultState = {
    isLoading: false,
    environmentModel: {},
    success: false,
  };
  
  const environmentReducer = (state = defaultState, action) => {
    switch (action.type) {
      case ENVIRONMENT_BEGIN:
        return {
          ...state,
          isLoading: true,
          state:'begin',
        };
      case ENVIRONMENT_SUCCESS:
        return {
          ...state,
          environmentModel: action.payload,
          isLoading: false,
          success: true,
        };
      case ENVIRONMENT_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: action.payload,
          success: false,
        };
      default:
        return state;
    }
  };
  
  export default environmentReducer;
  