import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as toastrReducer } from 'react-redux-toastr';
import loginModelReducer from './app/fragments/login/reducers/loginModelReducer';
import loginResponseReducer from './app/fragments/login/reducers/loginReducer';
import resetPasswordModelReducer from './app/fragments/reset-password/reducers/resetPasswordModelReducer';
import resetPasswordResponseReducer from './app/fragments/reset-password/reducers/resetPasswordReducer';
import forgotPasswordModelReducer from './app/fragments/forgot-password/reducers/forgotPasswordModelReducer';
import twoFactorAuthenticationModelReducer from './app/fragments/two-factor-authentication/reducers/twoFactorAuthenticationModelReducer';
import validateTokenStateReducer from './app/fragments/two-factor-authentication/reducers/validateTokenStateReducer';
import resendTokenStateReducer from './app/fragments/two-factor-authentication/reducers/resendTokenReducer';
import environmentReducer from './app/fragments/environment-indicator/environmentReducer'

export default history => combineReducers({
    router: connectRouter(history),
    loginModelReducer,
    loginResponseReducer,
    resetPasswordModelReducer,
    resetPasswordResponseReducer,
    forgotPasswordModelReducer,
    twoFactorAuthenticationModelReducer,
    validateTokenStateReducer,
    resendTokenStateReducer,
    environmentReducer,
    toastr: toastrReducer,
});
