import * as React from 'react';
import { connect } from 'react-redux';
import { } from 'redux';
import { toastr } from 'react-redux-toastr';
import { getTwoFactorAuthenticationModel } from '../actions/twoFactorAuthenticationAction';
import { validateToken } from '../actions/validateTokenAction';
import { resendToken } from '../actions/resendTokenAction';
import { loginToIdentity } from '../../login/services/IdentityService';
import { getIdentityServerLoginModel } from '../../login/services/IdentityServerLoginModelExtractorService';
import { history } from '../../../../configureStore';

import { TleTitle, TleForm, TleButton, TleLink, TleLabel,  TleFormInputField, TleWidgetContainer } from '../../../common/index';

export class TwoFactorAuthenticationV2Component extends React.Component {
    constructor(props) {
        super(props);

        this.initializeState();
        this.initializeHandlers();
    }

    componentDidMount() {
        this.props.getTwoFactorAuthenticationModel();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.ValidateTokenState.isLoading &&
            !this.props.ValidateTokenState.isLoading) {
            this.handleValidateTokenResponse();
        }

        if (prevProps.ResendTokenState.isLoading &&
            !this.props.ResendTokenState.isLoading) {
            this.handleResendTokenResponse();
        }
    }

    handleResendTokenResponse() {
        if (this.props.ResendTokenState.error || !this.props.ResendTokenState.sentTokenResult) {
            let errorMessage = this.props.TwoFactorAuthentificationModelState.twoFactorAuthenticationModel.commonTranslations.internalError;
            toastr.error(errorMessage);
            return;
        }
        toastr.success(this.props.TwoFactorAuthentificationModelState.twoFactorAuthenticationModel.verificationCodeModel.verificationCodeSentSuccessMessage);
    }

    initializeState() {
        this.state = {
            token: '',
            isTokenValid: true
        };

        if (this.props.location.state === undefined) {
            history.push('/unauthorized');
            return;
        }
        this.state.username = this.props.location.state.username;
        this.state.password = this.props.location.state.password;
        this.state.scope = this.props.location.state.scope;
        this.state.userRef = this.props.location.state.userRef;
    }

    initializeHandlers() {
        this.onSubmit = this.onSubmit.bind(this);
        this.onTokenChange = this.onTokenChange.bind(this);
        this.resendToken = this.resendToken.bind(this);
    }

    onSubmit(e) {
        e.preventDefault();

        let tokenValid = this.checkToken();

        if (!tokenValid) {
            return;
        }

        let requestData = {
            "VerificationCode": this.state.token
        };

        this.props.validateToken(requestData);
    }

    onTokenChange(e) {
        this.setState({
            token: e.target.value,
        },
            this.checkToken);
    }

    resendToken(e) {
        e.preventDefault();
        if (this.props.ResendTokenState.isLoading) {
            return;
        }
        this.props.resendToken(this.state.userRef);
    }

    checkToken() {
        if (this.state.token === undefined || this.state.token == 0) {
            this.setState({
                isTokenValid: false
            });
            return false;
        }

        this.setState({
            isTokenValid: true
        });

        return true;
    }

    getTokenInputProps(twoFactorAuthenticationModel) {
        return {
            type: 'text',
            value: this.state.token,
            handleChange: this.onTokenChange,
            isValid: this.state.isTokenValid,
            placeholderText: twoFactorAuthenticationModel.verificationCodeModel.verificationCodeWatermark,
            validationText: twoFactorAuthenticationModel.commonTranslations.requiredFieldValidation,
            maxLength: 6
        };
    }

    handleValidateTokenResponse() {
        if (this.props.ValidateTokenState.error) {
            let errorMessage = this.props.TwoFactorAuthentificationModelState.twoFactorAuthenticationModel.commonTranslations.internalError;
            toastr.error(errorMessage);
            return;
        }

        if (!this.props.ValidateTokenState.validateTokenResult) {
            let invalidTokenMessage = this.props.TwoFactorAuthentificationModelState.twoFactorAuthenticationModel.verificationCodeModel.verificationCodeNotValid;
            toastr.error(invalidTokenMessage);
            return;
        }

        this.handleValidTokenSubmitted();
    }

    handleValidTokenSubmitted() {
        let loginModel = getIdentityServerLoginModel().LoginModel;
        loginToIdentity({
            antiForgery: loginModel.AntiForgery,
            userName: this.state.username,
            password: this.state.password,
            loginUrl: loginModel.LoginUrl
        });
    }

    render() {
        let twoFactorAuthenticationModel = this.props.TwoFactorAuthentificationModelState.twoFactorAuthenticationModel;

        return (
            <div>
                <TleWidgetContainer customClassName="widget widget-login">
                    <TleTitle titleText={twoFactorAuthenticationModel.verificationCodeModel.signInVerificationCodeTitle} />
                    <TleForm customClassName="widget-body login-form"
                        noValidate={true}
                        onSubmit={this.onSubmit}>
                        <TleLabel text={twoFactorAuthenticationModel.verificationCodeModel.verificationCodeDescription} />
                        <TleFormInputField inputProps={this.getTokenInputProps(twoFactorAuthenticationModel)} />
                        <TleButton id={"loginButton"}
                          text={twoFactorAuthenticationModel.commonTranslations.submitButton}
                          customClassName={"btn btn-primary btn-login"} />
                    </TleForm>
                    <TleLink text={twoFactorAuthenticationModel.verificationCodeModel.resendVerificationCodeLink} onClick={this.resendToken} />
                </TleWidgetContainer>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        TwoFactorAuthentificationModelState: state.twoFactorAuthenticationModelReducer,
        ValidateTokenState: state.validateTokenStateReducer,
        ResendTokenState: state.resendTokenStateReducer
    }
}

const mapDispatchToProps = dispatch =>
    ({
        getTwoFactorAuthenticationModel: () => dispatch(getTwoFactorAuthenticationModel()),
        validateToken: (data) => dispatch(validateToken(data)),
        resendToken: (data) => dispatch(resendToken(data))
    })

export default connect(mapStateToProps, mapDispatchToProps)(TwoFactorAuthenticationV2Component);