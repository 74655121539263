import {
    VALIDATE_TOKEN_BEGIN,
    VALIDATE_TOKEN_SUCCESS,
    VALIDATE_TOKEN_FAILURE,
  } from '../actions/validateTokenAction';


  const unloadedState = {
    isLoading: false,
    validateTokenResult: {
        isSuccess: '',
        AdminUserRef: '',
    },
    error: '',
  };

  const validateTokenStateReducer = (state = unloadedState, action) => {
    switch (action.type) {
      case VALIDATE_TOKEN_BEGIN:
        return {
          ...state,
          isLoading: true,
        };
      case VALIDATE_TOKEN_SUCCESS:
        return {
          ...state,
          validateTokenResult: action.payload,
          isLoading: false,
        };
      case VALIDATE_TOKEN_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };

  export default validateTokenStateReducer;
