import {
    RESEND_TOKEN_BEGIN,
    RESEND_TOKEN_FAILURE,
    RESEND_TOKEN_SUCCESS,
  } from '../actions/resendTokenAction';


  const unloadedState = {
    isLoading: false,
    sentTokenResult: false,
    error: '',
  };

  const resendTokenStateReducer = (state = unloadedState, action) => {
    switch (action.type) {
      case RESEND_TOKEN_BEGIN:
        return {
          ...state,
          isLoading: true,
        };
      case RESEND_TOKEN_SUCCESS:
        return {
          ...state,
          sentTokenResult: action.payload,
          isLoading: false,
        };
      case RESEND_TOKEN_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };

  export default resendTokenStateReducer;
