import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import defaultTheme from '../themes/defaultTheme';

const Form = styled.form``;

const TleForm = ({
 children, onSubmit, noValidate, customClassName, theme,
}) => (
  <ThemeProvider theme={theme}>
    <Form onSubmit={onSubmit} className={customClassName} noValidate={noValidate}>
      {children}
    </Form>
  </ThemeProvider>
);

TleForm.propTypes = {
  children: PropTypes.any,
  onSubmit: PropTypes.func.isRequired,
  noValidate: PropTypes.any,
  theme: PropTypes.any,
  customClassName: PropTypes.string,
};

TleForm.defaultProps = {
  noValidate: false,
  theme: defaultTheme,
  customClassName: '',
};

export default TleForm;
