import {
    RESET_PASSWORD_BEGIN,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,
  } from '../actions/resetPasswordAction';

  const unloadedState = {
    isLoading: false,
    resetPasswordResponse: {
      isSuccess: false,
    },
    error: '',
  };

  const resetPasswordResponseReducer = (state = unloadedState, action) => {
    switch (action.type) {
      case RESET_PASSWORD_BEGIN:
        return {
          ...state,
          isLoading: true,
        };
      case RESET_PASSWORD_SUCCESS:
        return {
          ...state,
          resetPasswordResponse: action.payload,
          isLoading: false,
          error: '',
        };
      case RESET_PASSWORD_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };

  export default resetPasswordResponseReducer;
