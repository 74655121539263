import { fetch } from 'domain-task';

export const RESET_PASSWORD_BEGIN = 'RESET_PASSWORD_BEGIN';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const resetPasswordBegin = () => ({
    type: RESET_PASSWORD_BEGIN,
});

export const resetPasswordSuccess = resetPasswordResponse => ({
    type: RESET_PASSWORD_SUCCESS,
    payload: resetPasswordResponse,
});

export const resetPasswordFailure = error => ({
    type: RESET_PASSWORD_FAILURE,
    payload: error,
});

export function resetPassword(userRef, resetPasswordRequestData) {
    return (dispatch) => {
        dispatch(resetPasswordBegin());
        const resetPasswordUrl = `/api/v1/security/${userRef}/password`;
        return fetch(
            resetPasswordUrl, {
                method: 'PATCH',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(resetPasswordRequestData),
            },
)
            .then(
                res => res.json(),
            )
            .then((data) => {
                if (data.isFailed) {
                    dispatch(resetPasswordFailure(data.errorMessage));
                } else {
                    dispatch(resetPasswordSuccess(data.result));
                }
            })
            .catch((error) => {
                dispatch(resetPasswordFailure(error));
            });
    };
}
