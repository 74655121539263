import * as React from 'react';
import { connect } from 'react-redux';
import { } from 'redux';

import { getForgotPasswordModel } from '../actions/forgotPasswordModelAction';
import { getAdminUser } from '../services/AdminUserService';
import { sendForgotPasswordEmail } from '../services/EmailService';
import { toastr } from 'react-redux-toastr';

import { TleTitle, TleForm, TleLabel, TleButton, TleLink, TleFormInputField, TleWidgetContainer } from '../../../common/index';

export class ForgotPasswordComponent extends React.Component {
    constructor(props) {
        super(props);

        this.initializeState();
        this.initializeHandlers();
    }

    componentDidMount() {
        this.getQueryParams();
        this.props.getForgotPasswordModel();
    }

    initializeHandlers() {
        this.onSubmit = this.onSubmit.bind(this);
        this.onEmailChange = this.onEmailChange.bind(this);
    }

    getQueryParams() {
        let queryParams = new URLSearchParams(location.search);
        let clientId = queryParams.get('clientId');
        let signin = queryParams.get('signin');
        let callbackUrl = queryParams.get('callbackUrl');
        let returnUrl = queryParams.get('returnUrl');

        this.setState({
            clientId: clientId == null ? "" : clientId,
            signin: signin == null ? "" : signin,
            callbackUrl: callbackUrl == null ? "" : callbackUrl,
            returnUrl: returnUrl == null ? "" : returnUrl,
        });
    }

    initializeState() {
        this.state = {
            email: "",
            isEmailValid: true,
            clientId: "",
            signin: "",
            callbackUrl: "",
            returnUrl: "",
            displaySecondStep: false
        };
    }

    checkEmail() {
        if (this.state.email == undefined || this.state.email.length == 0) {
            this.setState({
                isEmailValid: false
            });

            return false;
        }

        this.setState({
            isEmailValid: true
        });

        return true;
    }

    getEmailInputProps(forgotPasswordModel) {
        return {
            type: "text",
            value: this.state.email,
            handleChange: this.onEmailChange,
            isValid: this.state.isEmailValid,
            placeholderText: forgotPasswordModel.forgotPasswordTranslations.emailWatermark,
            validationText: forgotPasswordModel.commonTranslations.requiredFieldValidation
        }
    }

    getLoginUrl() {
        if(this.state.signin.length == 0) {
            return "/login?clientId=" + this.state.clientId +
                "&callbackUrl=" + encodeURIComponent(this.state.callbackUrl) +
                "&returnUrl=" + encodeURIComponent(this.state.returnUrl);
        } else {
            return "/core/login?signin=" + this.state.signin;
        }
    }

    sendForgotPasswordEmail() {
        sendForgotPasswordEmail(
            this.state.email, 
            this.state.clientId, 
            this.state.callbackUrl, 
            this.state.returnUrl, 
            this.state.signin)
        .then(
            (isSuccessful) => {
                if (isSuccessful) {
                    this.displaySecondStep();
                }
            },
            function (error) {
                toastr.error(this.props.ForgotPasswordModelState.forgotPasswordModel.commonTranslations.internalError);
            }
        );
    }

    displaySecondStep() {
        this.setState({
            displaySecondStep: true
        });
    }

    // #region Events

    onSubmit(e) {
        e.preventDefault();

        var emailValid = this.checkEmail();

        if (!emailValid) {
            return;
        }

        getAdminUser(this.state.email).then(
            (adminUserInfo) => {
                if (adminUserInfo != null) {
                    this.sendForgotPasswordEmail();
                } else {
                    this.displaySecondStep();
                }
            },
            function (error) {
                toastr.error(this.props.ForgotPasswordModelState.forgotPasswordModel.commonTranslations.internalError);
            }
        );
    }

    onEmailChange(e) {
        this.setState({
            email: e.target.value,
        },
            this.checkEmail);
    }

    // #endregion Events

    render() {
        let forgotPasswordModel = this.props.ForgotPasswordModelState.forgotPasswordModel;

        return (
            <div>
                <TleWidgetContainer customClassName="widget widget-login">
                    <TleTitle titleText={forgotPasswordModel.forgotPasswordTranslations.forgotPasswordTitle} />
                    {this.state.displaySecondStep ? (
                        <TleLabel text={forgotPasswordModel.forgotPasswordTranslations.forgotPasswordFurtherInstructions} customClassName="forgot-password-second-step" />
                    ) : (
                            <TleForm customClassName="widget-body login-form"
                                onSubmit={this.onSubmit}
                                noValidate={true}>
                                <TleLabel text={forgotPasswordModel.forgotPasswordTranslations.forgotPasswordDescription} />
                                <TleFormInputField inputProps={this.getEmailInputProps(forgotPasswordModel)} />
                                <TleButton id={"submitButton"}
                                    text={forgotPasswordModel.commonTranslations.submitButton}
                                    customClassName={"btn btn-primary btn-login"}>
                                </TleButton>
                            </TleForm>
                        )}
                    <TleLink href={this.getLoginUrl()}
                        text={forgotPasswordModel.forgotPasswordTranslations.loginLink} />
                </TleWidgetContainer>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        ForgotPasswordModelState: state.forgotPasswordModelReducer
    }
}

const mapDispatchToProps = dispatch =>
    ({
        getForgotPasswordModel: () => dispatch(getForgotPasswordModel())
    })

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordComponent);