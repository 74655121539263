import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import defaultTheme from '../themes/defaultTheme';

const Input = styled.input`
background-color: ${props => props.theme.bgColor};
font-family: ${props => props.theme.fontFamily};
display: inline-block;
`;
const TleInput = ({
 type, handleChange, placeholderText, tooltipText, id, value, customClassName, theme, maxLength,
}) => (
  <ThemeProvider theme={theme}>
    <Input
      data-tip={tooltipText}
      data-place="right"
      data-effect="solid"
      type={type}
      id={id}
      onChange={handleChange}
      value={value}
      className={customClassName}
      placeholder={placeholderText}
      maxLength={maxLength}
    />
  </ThemeProvider>
);

TleInput.propTypes = {
  type: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  placeholderText: PropTypes.string.isRequired,
  tooltipText: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  theme: PropTypes.any,
  customClassName: PropTypes.string,
  maxLength: PropTypes.any,
};

TleInput.defaultProps = {
  theme: defaultTheme,
  value: '',
  tooltipText: '',
};

export default TleInput;
