//import "./web.config";
import './assets/styles/application.scss';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import configureStore, { history } from './configureStore';
import Routes from './routes';
import EnvironmentIndicatorComponent from './app/fragments/environment-indicator/EnvironmentIndicator'

const initialState = window.initialReduxState;
const store = configureStore(initialState);

const render = () => {
    ReactDOM.render(        
      <Provider store={store}>
        <EnvironmentIndicatorComponent />
        <ConnectedRouter history={history}>
          <Routes />
        </ConnectedRouter>
      </Provider>,
      document.getElementById('react-app'),
  );
};

render();