import * as React from 'react';
import { Route, Switch } from 'react-router';
import Layout from './layout';
import LoginV2 from './app/fragments/login/components/LoginV2';
import Home from './app/fragments/home/components/Home';
import ResetPassword from './app/fragments/reset-password/components/ResetPassword';
import ForgotPassword from './app/fragments/forgot-password/components/ForgotPassword';
import Unauthorized from './app/fragments/reset-password/components/Unauthorized';
import TwoFactorAuthenticationV2 from './app/fragments/two-factor-authentication/components/TwoFactorAuthenticationV2';
import ReactTooltip from 'react-tooltip'

const Routes = () => (
  <Layout>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/core/login" component={LoginV2} />
      <Route path="/reset-password" component={ResetPassword} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/unauthorized" component={Unauthorized} />
      <Route path="/core/2fa" component={TwoFactorAuthenticationV2}/>
    </Switch>
    <ReactTooltip />
  </Layout>
);

export default Routes;
