import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import defaultTheme from '../themes/defaultTheme';

const Button = styled.button``;

const TleButton = ({
  id,
  text,
  onClick,
  disabled,
  customClassName,
  theme,
}) => (
  <ThemeProvider theme={theme}>
    <Button
      id={id}
      onClick={onClick}
      disabled={disabled}
      className={customClassName}
    >
      {text}
    </Button>
  </ThemeProvider>
);

TleButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  customClassName: PropTypes.string,
  theme: PropTypes.any,
};

TleButton.defaultProps = {
  theme: defaultTheme,
  text: 'Button',
  disabled: false,
};

export default TleButton;
