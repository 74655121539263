import { fetch } from 'domain-task';

export const GET_RESET_PASSWORD_MODEL_BEGIN = 'GET_RESET_PASSWORD_MODEL_BEGIN';
export const GET_RESET_PASSWORD_MODEL_SUCCESS = 'GET_RESET_PASSWORD_MODEL_SUCCESS';
export const GET_RESET_PASSWORD_MODEL_FAILURE = 'GET_RESET_PASSWORD_MODEL_FAILURE';

export const getResetPasswordModelBegin = () => ({
    type: GET_RESET_PASSWORD_MODEL_BEGIN,
});

export const getResetPasswordModelSuccess = loginModel => ({
    type: GET_RESET_PASSWORD_MODEL_SUCCESS,
    payload: loginModel,
});

export const getResetPasswordModelFailure = error => ({
    type: GET_RESET_PASSWORD_MODEL_FAILURE,
    payload: error,
});

export function getResetPasswordModel() {
    return (dispatch) => {
        dispatch(getResetPasswordModelBegin());
        fetch(
            '/api/v1/security/reset-password-model/language', {
                method: 'get',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            },
)
            .then(
                res => res.json(),
            )
            .then((data) => {
                dispatch(getResetPasswordModelSuccess(data.result));
            })
            .catch(error => dispatch(getResetPasswordModelFailure(error)));
    };
}
