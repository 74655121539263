import { fetch } from 'domain-task';

export const ENVIRONMENT_BEGIN = 'ENVIRONMENT_BEGIN';
export const ENVIRONMENT_SUCCESS = 'ENVIRONMENT_SUCCESS';
export const ENVIRONMENT_FAILURE = 'ENVIRONMENT_FAILURE';

export const environmentSuccess = environmentResponse => ({
    type: ENVIRONMENT_SUCCESS,
    payload: environmentResponse,
});

export const environmentFailure = environmentResponse => ({
    type: ENVIRONMENT_FAILURE,
    payload: environmentResponse,
});

export const environmentBegin = () => ({
    type: ENVIRONMENT_BEGIN,
});

export function getEnvironment() {
    return (dispatch) => {        
        dispatch(environmentBegin());        
        fetch(
            '/api/v1/security/environment-indicator', {
            method: 'get',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        },
        ).then(
            res => res.json()
        ).then((data) => {
            if (data.isFailed) {
                dispatch(environmentFailure(data.errorMessage));
            } else {
                dispatch(environmentSuccess(data.result));
            }
        }).catch((error) => {
            dispatch(environmentFailure(error));
        });
    };
}
