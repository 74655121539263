import * as React from 'react';
import { connect } from 'react-redux';
import { } from 'redux';
import { history } from '../../../../configureStore';
import { checkResetPasswordToken } from '../services/ResetPasswordTokenService';
import { checkPasswordStrength } from '../services/PasswordStrengthService';
import { getResetPasswordModel } from '../actions/resetPasswordModelAction';
import { resetPassword } from '../actions/resetPasswordAction';
import { toastr } from 'react-redux-toastr';
import ReactTooltip from 'react-tooltip';
import { getIdentityServerLoginModel } from '../../login/services/IdentityServerLoginModelExtractorService';

import { TleTitle, TleForm, TleButton, TleLabel, TleFormInputField, TleWidgetContainer } from '../../../common/index';

export class ResetPasswordComponent extends React.Component {
    constructor(props) {
        super(props);

        this.initializeState();
        this.initializeHandlers();
    }

    componentDidMount() {
        ReactTooltip.rebuild();

        this.getQueryParams();
        this.props.getResetPasswordModel();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.ResetPasswordResponseState.isLoading &&
            !this.props.ResetPasswordResponseState.isLoading) {
            this.handleResetPasswordResponse();
        }
    }

    initializeState() {
        this.state = {
            token: "",
            userRef: "",
            userName: "",
            password: "",
            isPasswordValid: true,
            passwordValidationText: "",
            passwordConfirm: "",
            isPasswordConfirmValid: true,
            passwordConfirmValidationText: ""
        };
    }

    initializeHandlers() {
        this.onSubmit = this.onSubmit.bind(this);
        this.onPasswordChange = this.onPasswordChange.bind(this);
        this.onPasswordConfirmChange = this.onPasswordConfirmChange.bind(this);
    }

    getQueryParams() {
        let queryParams = new URLSearchParams(location.search);
        let token = queryParams.get('token');
        let clientId = queryParams.get('clientId');
        let signin = queryParams.get('signin');
        let callbackUrl = queryParams.get('callbackUrl');
        let returnUrl = queryParams.get('returnUrl');

        this.setState({
            token: token == null ? "" : token,
            clientId: clientId == null ? "" : clientId,
            signin: signin == null ? "" : signin,
            callbackUrl: callbackUrl == null ? "" : callbackUrl,
            returnUrl: returnUrl == null ? "" : returnUrl
        },
            this.checkToken);
    }

    checkToken() {
        if (this.state.token == null || this.state.token.length == 0) {
            this.redirectToUnauthorizedPage();
        }

        checkResetPasswordToken(this.state.token)
            .then(
                (response) => {
                    if (response == null || !response.isValid) {
                        this.redirectToUnauthorizedPage();
                    }
                    else {
                        this.setState(Object.assign(this.state, { userRef: response.userRef == null ? 0 : response.userRef }));
                        this.setState(Object.assign(this.state, { userName: response.userName == null ? "" : response.userName }));
                        this.setState(Object.assign(this.state, { clientId: response.clientId == null ? this.state.clientId : response.clientId }));
                        this.setState(Object.assign(this.state, { signin: response.signIn == null ? this.state.signin : response.signIn }));
                        this.setState(Object.assign(this.state, { callbackUrl: response.callbackUrl == null ? this.state.callbackUrl : response.callbackUrl }));
                        this.setState(Object.assign(this.state, { returnUrl: response.returnUrl == null ? this.state.returnUrl : response.returnUrl }));
                    }
                });
    }

    redirectToUnauthorizedPage() {
        history.push('/unauthorized');
    }

    handleResetPasswordResponse() {
        if (this.props.ResetPasswordResponseState.error) {
            // internal error
            toastr.error(this.props.ResetPasswordModelState.resetPasswordModel.internalError);
            return;
        }

        if (this.props.ResetPasswordResponseState.resetPasswordResponse == null ||
            !this.props.ResetPasswordResponseState.resetPasswordResponse.isSuccess) {
            // validation error
            toastr.error(this.props.ResetPasswordModelState.resetPasswordModel.internalError);
            return;
        }

        toastr.success(this.props.ResetPasswordModelState.resetPasswordModel.passwordChangedSuccesfully);

        setTimeout(function () {
            this.setState(Object.assign(this.state, { token: '' }));
            this.redirectToLogin();
        }.bind(this), 3);
    }

    redirectToLogin() {
        if(this.state.signin.length == 0) {
            let loginUrl = this.getOldLoginUrl();
            history.push(loginUrl);    
        } else {
            let identityLoginModel = getIdentityServerLoginModel();

            if(identityLoginModel == null) {
                window.location.href = this.state.returnUrl;
            } else {
                let loginUrl = "/core/login?signin=" + this.state.signin;
                history.push(loginUrl);  
            }
        }
    }

    getOldLoginUrl() {
        return "/login?clientId=" + this.state.clientId +
            "&callbackUrl=" + encodeURIComponent(this.state.callbackUrl) +
            "&returnUrl=" + encodeURIComponent(this.state.returnUrl);
    }

    checkPassword() {
        if (this.state.password == undefined || this.state.password.length == 0) {
            this.setState({
                isPasswordValid: false,
                passwordValidationText: this.props.ResetPasswordModelState.resetPasswordModel.requiredFieldValidation
            });

            return false;
        }

        if (!checkPasswordStrength(this.state.password)) {
            this.setState({
                isPasswordValid: false,
                passwordValidationText: this.props.ResetPasswordModelState.resetPasswordModel.passwordNotValid
            });

            return false;
        }

        if (this.state.password == this.state.userName) {
            this.setState({
                isPasswordValid: false,
                passwordValidationText: this.props.ResetPasswordModelState.resetPasswordModel.passwordSameAsUsername
            });

            return false;
        }

        this.setState({
            isPasswordValid: true
        });

        return true;
    }

    checkConfirmPassword() {
        if (this.state.passwordConfirm == undefined || this.state.passwordConfirm.length == 0) {
            this.setState({
                isPasswordConfirmValid: false,
                passwordConfirmValidationText: this.props.ResetPasswordModelState.resetPasswordModel.requiredFieldValidation
            });

            return false;
        }

        if (this.state.password != this.state.passwordConfirm) {
            this.setState({
                isPasswordConfirmValid: false,
                passwordConfirmValidationText: this.props.ResetPasswordModelState.resetPasswordModel.passwordsDoNotMatch
            });

            return false;
        }

        this.setState({
            isPasswordConfirmValid: true
        });

        return true;
    }

    getPasswordInputProps(resetPasswordModel) {
        return {
            type: "password",
            value: this.state.password,
            handleChange: this.onPasswordChange,
            isValid: this.state.isPasswordValid,
            placeholderText: resetPasswordModel.newPasswordWatermark,
            tooltipText: resetPasswordModel.newPasswordHint,
            validationText: this.state.passwordValidationText
        }
    }

    getPasswordConfirmInputProps(resetPasswordModel) {
        return {
            type: "password",
            value: this.state.passwordConfirm,
            handleChange: this.onPasswordConfirmChange,
            isValid: this.state.isPasswordConfirmValid,
            placeholderText: resetPasswordModel.confirmPasswordWatermark,
            tooltipText: resetPasswordModel.confirmPasswordHint,
            validationText: this.state.passwordConfirmValidationText
        }
    }

    // #region Events

    onSubmit(e) {
        e.preventDefault();

        var passwordValid = this.checkPassword();
        var passwordConfirmValid = this.checkConfirmPassword();

        if (!passwordValid || !passwordConfirmValid) {
            return;
        }

        let requestData = {
            "AdminUserRef": this.state.userRef,
            "NewPassword": this.state.password
        }

        this.props.resetPassword(this.state.userRef, requestData);
    }

    onPasswordChange(e) {
        this.setState({
            password: e.target.value,
        },
            this.checkPassword);
    }

    onPasswordConfirmChange(e) {
        this.setState({
            passwordConfirm: e.target.value,
        },
            this.checkConfirmPassword);
    }

    // #endregion Events

    render() {
        let resetPasswordModel = this.props.ResetPasswordModelState.resetPasswordModel;

        return (
            <div>
                <TleWidgetContainer customClassName="widget widget-login">
                    <TleTitle titleText={resetPasswordModel.resetPasswordTitle} />
                    <TleForm customClassName="widget-body login-form"
                        onSubmit={this.onSubmit}
                        noValidate={true}>
                        <TleLabel text={resetPasswordModel.resetPasswordDescription} />
                        <TleFormInputField inputProps={this.getPasswordInputProps(resetPasswordModel)} />
                        <TleFormInputField inputProps={this.getPasswordConfirmInputProps(resetPasswordModel)} />
                        <TleButton id={"loginButton"}
                            text={resetPasswordModel.confirmButton}
                            customClassName={"btn btn-primary btn-login"}>
                        </TleButton>
                    </TleForm>
                </TleWidgetContainer>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        ResetPasswordModelState: state.resetPasswordModelReducer,
        ResetPasswordResponseState: state.resetPasswordResponseReducer,
    }
}

const mapDispatchToProps = dispatch =>
    ({
        getResetPasswordModel: () => dispatch(getResetPasswordModel()),
        resetPassword: (userRef, data) => dispatch(resetPassword(userRef, data))
    })

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordComponent);