import {
  LOGIN_BEGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
} from '../actions/loginAction';

const unloadedState = {
  isLoading: false,
  loginResponse: {
    success: false,
    isPasswordExpired: false,
    isTwoFactorRequired: false,
    scope: '',
    resetPasswordToken: '',
  },
  error: '',
};

const loginResponseReducer = (state = unloadedState, action) => {
  switch (action.type) {
    case LOGIN_BEGIN:
      return {
        ...state,
        isLoading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loginResponse: action.payload,
        isLoading: false,
        error: '',
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default loginResponseReducer;
