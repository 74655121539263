import * as React from 'react';
import { connect } from 'react-redux';
import { } from 'redux';
import { toastr } from 'react-redux-toastr';
import { history } from '../../../../configureStore';
import { getLoginModel } from '../actions/loginModelAction';
import { login } from '../actions/loginAction';
import { loginToIdentity } from '../services/IdentityService';
import { getIdentityServerLoginModel } from '../services/IdentityServerLoginModelExtractorService';

import { TleTitle, TleForm, TleButton, TleLink, TleFormInputField, TleWidgetContainer } from '../../../common/index';

export class LoginV2Component extends React.Component {
    constructor(props) {
        super(props);

        this.initializeState();
        this.initializeHandlers();
    }

    componentDidMount() {
        this.getQueryParams();
        this.props.getLoginModel();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.LoginResponseState.isLoading &&
            !this.props.LoginResponseState.isLoading) {
            this.handleLoginResponse();
        }
    }

    initializeHandlers() {
        this.onSubmit = this.onSubmit.bind(this);
        this.onUsernameChange = this.onUsernameChange.bind(this);
        this.onPasswordChange = this.onPasswordChange.bind(this);
    }

    getQueryParams() {
        let queryParams = new URLSearchParams(location.search);
        let signin = queryParams.get('signin');

        this.setState({
            signin: signin == null ? "" : signin
        });
    }

    initializeState() {
        let signinMessage = getIdentityServerLoginModel().SigninMessage;

        this.state = {
            userName: '',
            isUsernameValid: true,
            password: '',
            isPasswordValid: true,
            clientId: signinMessage.ClientId,
            signin: ''
        };
    }

    handleLoginResponse() {
        if (this.props.LoginResponseState.error) {
            // internal error
            toastr.error(this.props.LoginModelState.loginModel.commonTranslations.internalError);
            return;
        }

        if (!this.props.LoginResponseState.loginResponse.success) {
            // validation error
            toastr.error(this.props.LoginModelState.loginModel.loginTranslations.userNameOrPasswordNotValid);
            return;
        }

        if (this.props.LoginResponseState.loginResponse.isPasswordExpired) {
            let resetPasswordUrl = this.getResetPasswordUrl();
            history.push(resetPasswordUrl);
            return;
        }

        if (this.props.LoginResponseState.loginResponse.isTwoFactorRequired) {
            let twoFactorAuthenticationUrl = this.get2faUrl();
            history.push(twoFactorAuthenticationUrl,
                {
                    username: this.state.userName,
                    password: this.state.password,
                    scope: this.props.LoginResponseState.loginResponse.scope,
                    userRef: this.props.LoginResponseState.loginResponse.adminUserRef
                });
            return;
        }

        this.handleSuccessfulLogin();
    }

    handleSuccessfulLogin() {
        let loginModel = getIdentityServerLoginModel().LoginModel;

        loginToIdentity({
            antiForgery: loginModel.AntiForgery,
            userName: this.state.userName,
            password: this.state.password,
            loginUrl: loginModel.LoginUrl
        });
    }

    checkUsername() {
        if (this.state.userName === undefined || this.state.userName.length == 0) {
            this.setState({
                isUsernameValid: false
            });

            return false;
        }

        this.setState({
            isUsernameValid: true
        });

        return true;
    }

    checkPassword() {
        if (this.state.password === undefined || this.state.password.length == 0) {
            this.setState({
                isPasswordValid: false
            });

            return false;
        }

        this.setState({
            isPasswordValid: true
        });

        return true;
    }

    getUserNameInputProps(loginModel) {
        return {
            type: 'text',
            value: this.state.userName,
            handleChange: this.onUsernameChange,
            isValid: this.state.isUsernameValid,
            placeholderText: loginModel.loginTranslations.userNameWatermark,
            validationText: loginModel.commonTranslations.requiredFieldValidation
        }
    }

    getPasswordInputProps(loginModel) {
        return {
            type: 'password',
            value: this.state.password,
            handleChange: this.onPasswordChange,
            isValid: this.state.isPasswordValid,
            placeholderText: loginModel.loginTranslations.passwordWatermark,
            validationText: loginModel.commonTranslations.requiredFieldValidation
        };
    }

    getResetPasswordUrl() {
        return "/reset-password?token=" + this.props.LoginResponseState.loginResponse.resetPasswordToken + 
            "&signin=" + this.state.signin;
    }

    getForgotPasswordUrl() {
        return "/forgot-password?signin=" + this.state.signin;
    }

    get2faUrl() {
        return "/core/2fa";
    }

    // #region Events

    onSubmit(e) {
        e.preventDefault();

        let usernameValid = this.checkUsername();
        let passwordValid = this.checkPassword();

        if (!usernameValid || !passwordValid) {
            return;
        }

        let requestData = {
            "UserName": this.state.userName,
            "Password": this.state.password,
            "ClientId": this.state.clientId,
        }

        this.props.login(requestData);
    }

    onUsernameChange(e) {
        this.setState({
            userName: e.target.value,
        },
            this.checkUsername);
    }

    onPasswordChange(e) {
        this.setState({
            password: e.target.value,
        },
            this.checkPassword);
    }

    // #endregion Events

    render() {
        let loginModel = this.props.LoginModelState.loginModel;

        return (
            <div>
                <TleWidgetContainer customClassName="widget widget-login">
                    <TleTitle titleText={loginModel.loginTranslations.title} />
                    <TleForm customClassName="widget-body login-form"
                        onSubmit={this.onSubmit}
                        noValidate={true}>
                        <TleFormInputField inputProps={this.getUserNameInputProps(loginModel)} />
                        <TleFormInputField inputProps={this.getPasswordInputProps(loginModel)} />
                        <TleButton id={"loginButton"}
                            text={loginModel.loginTranslations.loginButton}
                            customClassName={"btn btn-primary btn-login"}>
                        </TleButton>
                    </TleForm>
                    <TleLink href={this.getForgotPasswordUrl()}
                        text={loginModel.loginTranslations.forgotPassword} />
                </TleWidgetContainer>
               
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        LoginModelState: state.loginModelReducer,
        LoginResponseState: state.loginResponseReducer,
    }
}

const mapDispatchToProps = dispatch =>
    ({
        getLoginModel: () => dispatch(getLoginModel()),
        login: (data) => dispatch(login(data))
    })

export default connect(mapStateToProps, mapDispatchToProps)(LoginV2Component);