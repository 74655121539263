// eslint-disable-next-line import/prefer-default-export
export function getIdentityServerLoginModel() {
    const model = window.identityServerLoginModel;

    // override antiforgery
    const antiForgery = localStorage.getItem('xsrf');
    if (antiForgery != null && antiForgery.length > 0) {
        model.LoginModel.AntiForgery.Value = antiForgery;
    }
    return model;
}
