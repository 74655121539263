import * as React from 'react';
import { connect } from 'react-redux';
import {} from 'redux';

import {TleTitle,  TleWidgetContainer } from '../../../common/index';

export class UnauthorizedComponent extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <TleWidgetContainer customClassName="widget widget-login">
                    <TleTitle titleText="403 - Unauthorized" />
                </TleWidgetContainer>
            </div>
        )
    }
}

export default UnauthorizedComponent;