import {
    GET_TWO_FACTOR_AUTHENTICATION_BEGIN,
    GET_TWO_FACTOR_AUTHENTICATION_FAILURE,
    GET_TWO_FACTOR_AUTHENTICATION_SUCCESS,
  } from '../actions/twoFactorAuthenticationAction';


  const unloadedState = {
    isLoading: false,
    twoFactorAuthenticationModel: {
      commonTranslations: {
        internalError: '',
        requiredFieldValidation: '',
        submitButton: '',
      },
      verificationCodeModel: {
        signInVerificationCodeTitle: '',
        verificationCodeWatermark: '',
        verificationCodeDescription: '',
        verificationCodeNotValid: '',
        resendVerificationCodeLink: '',
        verificationCodeSentSuccessMessage: '',
      },
    },
    error: '',
  };

  const twoFactorAuthenticationModelReducer = (state = unloadedState, action) => {
    switch (action.type) {
      case GET_TWO_FACTOR_AUTHENTICATION_BEGIN:
        return {
          ...state,
          isLoading: true,
        };
      case GET_TWO_FACTOR_AUTHENTICATION_SUCCESS:
        return {
          ...state,
          twoFactorAuthenticationModel: action.payload,
          isLoading: false,
        };
      case GET_TWO_FACTOR_AUTHENTICATION_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };

  export default twoFactorAuthenticationModelReducer;
