import { fetch } from 'domain-task';

export function getAdminUser(email) {
    const url = `/api/v1/security/admin-user/by-email/${email}`;

    return fetch(
        url,
        {
            method: 'get',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        },
)
    .then(
        res => res.json(),
    )
    .then(data => data.result)
    .catch(error => console.log(error));
}
