import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import defaultTheme from '../themes/defaultTheme';

const WidgetContainer = styled.div``;

const TleWidgetContainer = ({ children, customClassName, theme }) => (
  <ThemeProvider theme={theme}>
    <WidgetContainer className={customClassName}>
      {children}
    </WidgetContainer>
  </ThemeProvider>
);

TleWidgetContainer.propTypes = {
  children: PropTypes.any,
  theme: PropTypes.any,
  customClassName: PropTypes.string,
};

TleWidgetContainer.defaultProps = {
  theme: defaultTheme,
  customClassName: '',
};

export default TleWidgetContainer;
