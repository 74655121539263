import { fetch } from 'domain-task';

export function sendForgotPasswordEmail(email, clientId, callbackUrl, returnUrl, signin) {
    const url = '/api/v1/security/forgot-password/send-email/1';
    const requestData = {
        Email: email,
        ClientId: clientId,
        CallbackUrl: callbackUrl,
        ReturnUrl: returnUrl,
        SignIn: signin,
    };

    return fetch(
        url,
        {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
        },
)
        .then(
            res => res.json(),
        )
        .then(data => data.result)
        .catch(error => console.log(error));
}
