import { fetch } from 'domain-task';

export const GET_FORGOT_PASSWORD_MODEL_BEGIN = 'GET_FORGOT_PASSWORD_MODEL_BEGIN';
export const GET_FORGOT_PASSWORD_MODEL_SUCCESS = 'GET_FORGOT_PASSWORD_MODEL_SUCCESS';
export const GET_FORGOT_PASSWORD_MODEL_FAILURE = 'GET_FORGOT_PASSWORD_MODEL_FAILURE';

export const getForgotPasswordModelBegin = () => ({
    type: GET_FORGOT_PASSWORD_MODEL_BEGIN,
});

export const getForgotPasswordModelSuccess = forgotPasswordModel => ({
    type: GET_FORGOT_PASSWORD_MODEL_SUCCESS,
    payload: forgotPasswordModel,
});

export const getForgotPasswordModelFailure = error => ({
    type: GET_FORGOT_PASSWORD_MODEL_FAILURE,
    payload: error,
});

export function getForgotPasswordModel() {
    return (dispatch) => {
        dispatch(getForgotPasswordModelBegin());
        fetch(
            '/api/v1/security/forgot-password-model/language/1', {
                method: 'get',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            },
)
            .then(
                res => res.json(),
            )
            .then((data) => {
                dispatch(getForgotPasswordModelSuccess(data.result));
            })
            .catch(error => dispatch(getForgotPasswordModelFailure(error)));
    };
}
