import * as React from 'react';
import { connect } from 'react-redux';
import { getEnvironment} from './environmentIndicatorAction'
import { TleStickyNotification } from '../../common/index';

export class EnvironmentIndicatorComponent extends React.Component {
    constructor(props) {
        super(props);
        this.initializeState();
    }

    initializeState() {
        this.state = {
            environmentModel : {}
        };
    }

    componentDidMount() {
        this.props.getEnvironment();
    }

    getBackground(environment){        
        switch(environment){
            case "production":
                return 'red';
            case "productionnj":
                return 'blue';
            case "integration":
                return 'orange';
            default:
                return 'green';
        }
    }

    render() {
        const { environmentResponseState } = this.props;        
        const {environmentModel, success, isLoading}  = environmentResponseState;
        
        if(isLoading || !success) {
            return null;
        }

        const { environment, translation } = environmentModel;
        
        return (            
            <TleStickyNotification text={translation} background={this.getBackground(environment)}>
            </TleStickyNotification>
        );
    }
    
}

const mapStateToProps = state => {
    return {
        environmentResponseState: state.environmentReducer,
    }
}

const mapDispatchToProps = dispatch => {    
    return {
      getEnvironment: () => dispatch(getEnvironment()),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(EnvironmentIndicatorComponent);