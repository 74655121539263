import {
    GET_RESET_PASSWORD_MODEL_BEGIN,
    GET_RESET_PASSWORD_MODEL_SUCCESS,
    GET_RESET_PASSWORD_MODEL_FAILURE,
  } from '../actions/resetPasswordModelAction';


  const unloadedState = {
    isLoading: false,
    resetPasswordModel: {
        languageRef: '',
        newPasswordHint: '',
        confirmButton: '',
        confirmPasswordWatermark: '',
        newPasswordWatermark: '',
        passwordsDoNotMatch: '',
        resetPasswordDescription: '',
        confirmPasswordHint: '',
        resetPasswordTitle: '',
        passwordSameAsUsername: '',
        passwordNotValid: '',
        passwordChangedSuccesfully: '',
        internalError: '',
        requiredFieldValidation: '',
    },
    error: '',
  };

  const resetPasswordModelReducer = (state = unloadedState, action) => {
    switch (action.type) {
      case GET_RESET_PASSWORD_MODEL_BEGIN:
        return {
          ...state,
          isLoading: true,
        };
      case GET_RESET_PASSWORD_MODEL_SUCCESS:
        return {
          ...state,
          resetPasswordModel: action.payload,
          isLoading: false,
        };
      case GET_RESET_PASSWORD_MODEL_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };

  export default resetPasswordModelReducer;
