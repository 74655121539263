import {
    GET_FORGOT_PASSWORD_MODEL_BEGIN,
    GET_FORGOT_PASSWORD_MODEL_SUCCESS,
    GET_FORGOT_PASSWORD_MODEL_FAILURE,
  } from '../actions/forgotPasswordModelAction';


  const unloadedState = {
    isLoading: false,
    forgotPasswordModel: {
        commonTranslations: {
            internalError: '',
            requiredFieldValidation: '',
            submitButton: '',
        },
        forgotPasswordTranslations: {
            forgotPasswordFurtherInstructions: '',
            emailWatermark: '',
            forgotPasswordDescription: '',
            forgotPasswordTitle: '',
            loginLink: '',
        },
    },
    error: '',
  };

  const forgotPasswordModelReducer = (state = unloadedState, action) => {
    switch (action.type) {
      case GET_FORGOT_PASSWORD_MODEL_BEGIN:
        return {
          ...state,
          isLoading: true,
        };
      case GET_FORGOT_PASSWORD_MODEL_SUCCESS:
        return {
          ...state,
          forgotPasswordModel: action.payload,
          isLoading: false,
        };
      case GET_FORGOT_PASSWORD_MODEL_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };

  export default forgotPasswordModelReducer;
