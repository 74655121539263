import {
  GET_LOGIN_MODEL_BEGIN,
  GET_LOGIN_MODEL_SUCCESS,
  GET_LOGIN_MODEL_FAILURE,
} from '../actions/loginModelAction';


const unloadedState = {
  isLoading: false,
  loginModel: {
    commonTranslations: {
      internalError: '',
      requiredFieldValidation: '',
    },
    loginTranslations: {
      forgotPassword: '',
      loginButton: '',
      passwordWatermark: '',
      title: '',
      userNameOrPasswordNotValid: '',
      userNameWatermark: '',
    },
  },
  error: '',
};

const loginModelReducer = (state = unloadedState, action) => {
  switch (action.type) {
    case GET_LOGIN_MODEL_BEGIN:
      return {
        ...state,
        isLoading: true,
      };
    case GET_LOGIN_MODEL_SUCCESS:
      return {
        ...state,
        loginModel: action.payload,
        isLoading: false,
      };
    case GET_LOGIN_MODEL_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default loginModelReducer;
