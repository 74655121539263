import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import defaultTheme from '../themes/defaultTheme';

const Link = styled.a`
    font-family: ${props => props.theme.fontFamily};
`;

const TleLink = ({
 text, href, theme, onClick, customClassName,
}) => (
  <ThemeProvider theme={theme}>
    <Link href={href} className={customClassName} onClick={onClick}>
      {text}
    </Link>
  </ThemeProvider>
);

TleLink.propTypes = {
  text: PropTypes.string.isRequired,
  href: PropTypes.string,
  theme: PropTypes.any,
  customClassName: PropTypes.string,
  onClick: PropTypes.func,
};

TleLink.defaultProps = {
  theme: defaultTheme,
  href: '/',
};

export default TleLink;
