import { fetch } from 'domain-task';

export const RESEND_TOKEN_BEGIN = 'RESEND_TOKEN_BEGIN';
export const RESEND_TOKEN_SUCCESS = 'RESEND_TOKEN_SUCCESS';
export const RESEND_TOKEN_FAILURE = 'RESEND_TOKEN_FAILURE';

export const resendTokenBegin = () => ({
    type: RESEND_TOKEN_BEGIN,
});

export const resendTokenSuccess = resendTokenModel => ({
    type: RESEND_TOKEN_SUCCESS,
    payload: resendTokenModel,
});

export const resendTokenFailure = error => ({
    type: RESEND_TOKEN_FAILURE,
    payload: error,
});

export function resendToken(userRef) {
    return (dispatch) => {
        dispatch(resendTokenBegin());
        return fetch(
            `/api/v1/security/${userRef}/two-factor/send-verification-code`, {
                method: 'post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            },
)
            .then(
                res => res.json(),
            )
            .then((data) => {
                if (data.isFailed) {
                    dispatch(resendTokenFailure(data.errorMessage));
                } else {
                    dispatch(resendTokenSuccess(data.result));
                }
            })
            .catch((error) => {
                dispatch(resendTokenFailure(error));
            });
    };
}
