import React from 'react';
import PropTypes from 'prop-types';
import TleInput from '../atoms/InputField';
import TleLabel from '../atoms/Label';


const TleFormInputField = ({ inputProps }) => (
  <div className="form-group">
    <TleInput
      type={inputProps.type}
      id={inputProps.id}
      value={inputProps.value}
      handleChange={inputProps.handleChange}
      customClassName={inputProps.isValid ? 'form-control' : 'form-control invalid-form-input'}
      placeholderText={inputProps.placeholderText}
      tooltipText={inputProps.tooltipText}
      maxLength={inputProps.maxLength}
    />
    <TleLabel customClassName={inputProps.isValid ? 'input-validation hidden' : 'input-validation'} text={inputProps.validationText} />
  </div>
);

TleFormInputField.propTypes = {
    inputProps: PropTypes.shape({
        type: PropTypes.string.isRequired,
        id: PropTypes.string,
        value: PropTypes.string,
        handleChange: PropTypes.func.isRequired,
        isValid: PropTypes.any,
        placeholderText: PropTypes.string.isRequired,
        tooltipText: PropTypes.string,
        validationText: PropTypes.string.isRequired,
        maxLength: PropTypes.any,
    }),
};

export default TleFormInputField;
