import { fetch } from 'domain-task';

const tokenUrlTemplate = '/api/v1/security/reset-password-token/valid?token={token}';

export function checkResetPasswordToken(token) {
    const tokenUrl = tokenUrlTemplate.replace('{token}', token);
    return fetch(
        tokenUrl,
        {
            method: 'get',
        },
)
    .then(
        res => res.json(),
    )
    .then((data) => {
        if (data == null || data.isFailed || data.result == null) {
            return { isValid: false };
        }
        return data.result;
    })
    .catch(error => console.log(error));
}
