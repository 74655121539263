import React from 'react';
import ReduxToastr from 'react-redux-toastr';
import { TleImage, TleLogoContainer } from './app/common/index';
import logo from  './assets/images/thelotterLogo.png';

class Layout extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  render() {
    return (
      <section id="main">
        <article id="body">
          <div className="container-fluid">
            <div className="row">
              <div className="container">
                <div id="content" className="widget-login-container" role="main">
                  <div className="row">
                    <div className="col-xl-4 col-md-6 col-xs-10 offset-xl-4 offset-md-3 offset-xs-1">
                      {this.props.children}
                    </div>
                  </div>
                  <div className="row">
                  <div className="col-xl-4 col-md-6 col-xs-10 offset-xl-4 offset-md-3 offset-xs-1">
                  <TleLogoContainer customClassName="logo-container">
                    <TleImage src={logo}
                        alt={""}
                        customClassName={"center"} />
                  </TleLogoContainer>
                </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
        <ReduxToastr
          timeOut={4000}
          newestOnTop={true}
          position="bottom-center"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          preventDuplicates={false}
          className="customToaster" />
      </section>
    );
  }
}

export default Layout;
