import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import defaultTheme from '../themes/defaultTheme';

const Image = styled.img`
`;

const TleImage = ({
 src, alt, customClassName, theme,
}) => (
  <ThemeProvider theme={theme}>
    <Image src={src} alt={alt} className={customClassName} />
  </ThemeProvider>
);

TleImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  theme: PropTypes.any,
  customClassName: PropTypes.string,
};

TleImage.defaultProps = {
  theme: defaultTheme,
  customClassName: '',
  alt: '',
};

export default TleImage;
