import { fetch } from 'domain-task';

export const GET_TWO_FACTOR_AUTHENTICATION_BEGIN = 'GET_TWO_FACTOR_AUTHENTICATION_BEGIN';
export const GET_TWO_FACTOR_AUTHENTICATION_SUCCESS = 'GET_TWO_FACTOR_AUTHENTICATION_SUCCESS';
export const GET_TWO_FACTOR_AUTHENTICATION_FAILURE = 'GET_TWO_FACTOR_AUTHENTICATION_FAILURE';

export const getTwoFactorAuthenticationModelBegin = () => ({
    type: GET_TWO_FACTOR_AUTHENTICATION_BEGIN,
});

export const getTwoFactorAuthenticationSuccess = twoFactorAuthenticationModel => ({
    type: GET_TWO_FACTOR_AUTHENTICATION_SUCCESS,
    payload: twoFactorAuthenticationModel,
});

export const getTwoFactorAuthenticationFailure = error => ({
    type: GET_TWO_FACTOR_AUTHENTICATION_FAILURE,
    payload: error,
});

export function getTwoFactorAuthenticationModel() {
    return (dispatch) => {
        dispatch(getTwoFactorAuthenticationModelBegin());
        fetch(
            'api/v1/security/verification-code-model/language/1', {
                method: 'get',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            },
)
            .then(
                res => res.json(),
            )
            .then((data) => {
                dispatch(getTwoFactorAuthenticationSuccess(data.result));
            })
            .catch(error => dispatch(getTwoFactorAuthenticationFailure(error)));
    };
}
