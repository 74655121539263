
export function loginToIdentity(loginCredentials) {
    const form = document.createElement('form');
    form.setAttribute('action', loginCredentials.loginUrl);
    form.setAttribute('method', 'post');

    let input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('id', loginCredentials.antiForgery.Name);
    input.setAttribute('name', loginCredentials.antiForgery.Name);
    input.setAttribute('value', loginCredentials.antiForgery.Value);
    form.appendChild(input);

    input = document.createElement('input');
    input.setAttribute('type', 'text');
    input.setAttribute('id', 'username');
    input.setAttribute('name', 'username');
    input.setAttribute('value', loginCredentials.userName);
    form.appendChild(input);

    input = document.createElement('input');
    input.setAttribute('type', 'text');
    input.setAttribute('id', 'password');
    input.setAttribute('name', 'password');
    input.setAttribute('value', loginCredentials.password);
    form.appendChild(input);

    input = document.createElement('input');
    input.setAttribute('type', 'checkbox');
    input.setAttribute('id', 'rememberMe');
    input.setAttribute('name', 'rememberMe');
    input.setAttribute('value', true);
    form.appendChild(input);

    const div = document.createElement('div');
    div.setAttribute('style', 'display:none');
    div.appendChild(form);
    document.body.appendChild(div);
    document.forms[1].submit();
}
