import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import defaultTheme from '../themes/defaultTheme';

const LogoContainer = styled.div``;

const TleLogoContainer = ({ children, customClassName, theme }) => (
  <ThemeProvider theme={theme}>
    <LogoContainer className={customClassName}>
      {children}
    </LogoContainer>
  </ThemeProvider>
);

TleLogoContainer.propTypes = {
  children: PropTypes.any,
  theme: PropTypes.any,
  customClassName: PropTypes.string,
};

TleLogoContainer.defaultProps = {
  theme: defaultTheme,
  customClassName: '',
};

export default TleLogoContainer;
