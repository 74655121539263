import React from 'react';
import PropTypes from 'prop-types';
import TleLabel from '../atoms/Label';
import styled from 'styled-components';


const StyledStickyStripe = styled.div`
  background: ${props => props.background};
  text-align: center;
  height: 22px;
  color: white !important;
`;

const TleStickyNotification = ({
  text, background
 }) => (
    <StyledStickyStripe background={background}>
        <TleLabel text={text}/>
    </StyledStickyStripe>
);

TleStickyNotification.propTypes = {
  text: PropTypes.string.isRequired,
  background: PropTypes.string.isRequired,
};

export default TleStickyNotification;
