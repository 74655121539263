import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import defaultTheme from '../themes/defaultTheme';

const Header = styled.header`
background-color: ${props => props.theme.bgColor};
font-family: ${props => props.theme.fontFamily};
display: inline-block;
`;

const TleTitle = ({ titleText, customClassName, theme }) => (
  <ThemeProvider theme={theme}>
    <Header className={customClassName}>
      <h3>{titleText}</h3>
    </Header>
  </ThemeProvider>
);

TleTitle.propTypes = {
  titleText: PropTypes.string.isRequired,
  theme: PropTypes.any,
  customClassName: PropTypes.string,
};

TleTitle.defaultProps = {
  theme: defaultTheme,
  customClassName: '',
};

export default TleTitle;
