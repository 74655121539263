import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import defaultTheme from '../themes/defaultTheme';

const Label = styled.label`
    font-family: ${props => props.theme.fontFamily};
`;

const TleLabel = ({
 text, customClassName, theme, display,
}) => (
  <ThemeProvider theme={theme}>
    {display
    ? (
      <Label className={customClassName}>
        {text}
      </Label>
)
    : null
    }
  </ThemeProvider>
);

TleLabel.propTypes = {
  text: PropTypes.string.isRequired,
  theme: PropTypes.any,
  customClassName: PropTypes.string,
  display: PropTypes.any,
};

TleLabel.defaultProps = {
  theme: defaultTheme,
  customClassName: '',
  text: '',
  display: true,
};

export default TleLabel;
