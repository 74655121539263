import { fetch } from 'domain-task';

export const VALIDATE_TOKEN_BEGIN = 'VALIDATE_TOKENBEGIN';
export const VALIDATE_TOKEN_SUCCESS = 'VALIDATE_TOKEN_SUCCESS';
export const VALIDATE_TOKEN_FAILURE = 'VALIDATE_TOKEN_FAILURE';

export const validateTokenBegin = () => ({
    type: VALIDATE_TOKEN_BEGIN,
});

export const validateTokenSuccess = validateTokenModel => ({
    type: VALIDATE_TOKEN_SUCCESS,
    payload: validateTokenModel,
});

export const validateTokenFailure = error => ({
    type: VALIDATE_TOKEN_FAILURE,
    payload: error,
});

export function validateToken(validateTokenData) {
    return (dispatch) => {
        dispatch(validateTokenBegin());
        return fetch(
            '/api/v1/security/validate-verification-code', {
                method: 'post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(validateTokenData),
            },
)
            .then(
                res => res.json(),
            )
            .then((data) => {
                if (data.isFailed) {
                    dispatch(validateTokenFailure(data.errorMessage));
                } else {
                    dispatch(validateTokenSuccess(data.result));
                }
            })
            .catch((error) => {
                dispatch(validateTokenFailure(error));
            });
    };
}
